import React from "react";
import { Link } from "gatsby";
import Layout from "../../components/layout";
import { Container, Row, Col } from "react-bootstrap";
import { StaticImage } from "gatsby-plugin-image";
import Seo from "../../components/seo";

const BikeShelters = () => {
    return (
        <Layout>
                <Seo
      title="Marquesinas y Recintos Cerrados para Bicicletas - BIKESAFE"
      description="Ya han pasado los días en que tenía que dejar la bicicleta candada a un árbol que pareciera seguro en el parque o a un farola en la calle, esperando que no fuera el objetivo de un ladrón de bicicletas o de piezas de bicicletas. Una marquesina para bicicletas o un recinto cerrado se puede colocar en la mayoría de las zonas públicas, pero como cada vez son más las personas que tienen una bicicleta, hay mayor necesidad y más expectativas de disponer de marquesinas para bicicletas por parte de los aficionados a la bicicleta."
    />
            <Container>
                <Row>
                    <Col sm="12">
                        <h1 className="mt-5 mb-5 text-center uppercase">
                        Marquesinas y Recintos Cerrados para Bicicletas
                        </h1>
                    </Col>
                </Row>
                <Row>
                    <Col sm="12">
                        <p className="flow-text">
                        Ya han pasado los días en que tenía que dejar la bicicleta candada a un árbol que pareciera seguro en el parque o a un farola en la calle, esperando que no fuera el objetivo de un ladrón de bicicletas o de piezas de bicicletas. Una marquesina para bicicletas o un recinto cerrado se puede colocar en la mayoría de las zonas públicas, pero como cada vez son más las personas que tienen una bicicleta, hay mayor necesidad y más expectativas de disponer de marquesinas para bicicletas por parte de los aficionados a la bicicleta.
                        </p>
                        <p className="flow-text">Nuestra amplia gama de marquesinas para bicicletas es resistente, moderna y sumamente práctica. Independientemente de las dimensiones y el estilo que busque, tenemos el recinto cerrado para bicicletas adecuado para usted, que le garantiza total seguridad y protección frente a los ladrones de piezas de bicicletas.</p>
                    </Col>
                </Row>
            </Container>
            <Container>
                <Row>
                <hr/>
                    <Col sm="12" lg="4" className="p-2">
                        <Link to="/product/233">
                            <StaticImage placeholder="blurred"  className="fluid" src="../product/233/homepage-233.jpg"></StaticImage>
                            <h3 className="uppercase mt-3">Marquesina Gijón</h3>
                            <p>Marquesina disponible en acabado galvanizado o revestimiento pulverizado en color RAL con soporte para bicicletas toastrack galvanizado. Techo y estructuras que...</p>
                            <p className="shop-now">Solicite una cotización</p>
                        </Link>
                    </Col>
                    <Col sm="12" lg="4" className="p-2">
                        <Link to="/product/38">
                            <StaticImage placeholder="blurred"  className="fluid" src="../product/38/homepage-38.jpg"></StaticImage>
                            <h3 className="uppercase mt-3">Marquesina Ark</h3>
                            <p>Diseñado para duplicar el número de bicicletas que puede guardar en un espacio relativamente pequeño, con dos filas de aparcamiento de bicicletas. Marquesina disponible...</p>
                            <p className="shop-now">Solicite una cotización</p>
                        </Link>
                    </Col>
                    <Col sm="12" lg="4" className="p-2">
                        <Link to="/product/22">
                            <StaticImage placeholder="blurred" className="fluid" src="../product/22/homepage-22.jpg"></StaticImage>
                            <h3 className="uppercase mt-3">Marquesina Bristol</h3>
                            <p>La marquesina viene equipado con las mejores y más versátiles características para adaptarse a cualquier entorno. Refugio de acero dulce con fijaciones de tornillos...</p>
                            <p className="shop-now">Solicite una cotización</p>
                        </Link>
                    </Col>
                </Row>
            </Container>
        </Layout>
    )
}

export default BikeShelters;